@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0; overflow-y: auto; overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('/src/font/ClashDisplay-Bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'ClashDisplay';
  src: url('/src/font/ClashDisplay-Extralight.otf') format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'ClashDisplay';
  src: url('/src/font/ClashDisplay-Light.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'ClashDisplay';
  src: url('/src/font/ClashDisplay-Medium.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'ClashDisplay';
  src: url('/src/font/ClashDisplay-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'ClashDisplay';
  src: url('/src/font/ClashDisplay-Semibold.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'Satoshi';
  src: url('/src/font/Satoshi-Bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'Satoshi';
  src: url('/src/font/Satoshi-Italic.otf') format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'Satoshi';
  src: url('/src/font/Satoshi-Light.otf') format('opentype');
  font-weight: 300 ;
}
@font-face {
  font-family: 'Satoshi';
  src: url('/src/font/Satoshi-Medium.otf') format('opentype');
  font-weight: 500 ;
}
@font-face {
  font-family: 'Satoshi';
  src: url('/src/font/Satoshi-Regular.otf') format('opentype');
  font-weight: 400 ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-label { -webkit-text-stroke-width: 1px; -webkit-text-stroke-color: #FFF; }


.hero-section { background: #7a29ff; margin-top: 96px; height: auto; position: relative; position: relative; padding-bottom: 200px;}
.hero-section::before { content: ""; position: absolute; background: linear-gradient(180deg, rgba(122, 41, 255, 0) 55.22%, #000000 100%); top: 0; left: 0; right: 0; bottom: 0;}
.card-section { background: linear-gradient(180deg, rgba(0, 0, 0, 0) 69.08%, #000000 97.97%); position: relative;}

@media(max-width: 768px) {
  .hero-section { height: auto; padding-bottom: 50px; padding-left: 20px; padding-right: 20px;}
}