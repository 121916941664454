.navbar-item { z-index: 100; padding: 0px; backdrop-filter: blur(10px);}
.navbar-item { z-index: 100;  transition: ease 0.5s;}
.overlay { display: none; background-color: rgba(0, 0, 0, 0.66); position: fixed; top: 0; left: 0; bottom: 0; z-index: 80; transition: ease 0.5s; }


@media(max-width: 768px) {
    .navbar-item { padding: 30px 20px;}
}


@media(max-width: 768px) {
    .overlay { display: block;}
}
